import { css } from 'styled-components';

export const screenWidthMixin = css` 
    left: 50%;
    right: 50%;
    width: 90vw;
    margin-left: -45vw;
    margin-right: -45vw;
    position: relative;
`

export const contentLayoutMixin = css`
  margin: 0 auto;
  padding-left: ${props => props.theme.layout.contentPaddingSm};
  padding-right: ${props => props.theme.layout.contentPaddingSm};
  max-width: ${props => props.theme.layout.maxWidth};

  @media screen and (min-width: ${props => props.theme.devices.md}) {
    padding-left: ${props => props.theme.layout.contentPaddingMd};
    padding-right: ${props => props.theme.layout.contentPaddingMd};
  }

  @media screen and (min-width: ${props => props.theme.devices.xl}) {
    padding-left: ${props => props.theme.layout.contentPaddingLg};
    padding-right: ${props => props.theme.layout.contentPaddingLg};
  }
`

export const inputMixin = css`
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.border || 'inherit'};
  color:  ${props => props.theme.colors.font || 'inherit'};
  font-size:  ${props => props.theme.type.sizeForm || 'inherit'};
  padding: 1rem 0;
  background: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition-delay: 9999s;
  }
`;

export const labelMixin = css`
  width: 100%;
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  color:  ${props => props.theme.colors.font || 'inherit'};
  font-size: inherit;
  
  ${props => props.required ?
    `&:after {
        content: '*';
        color: ${props.theme.colors.highlight || 'red'};
        margin-left: 0.3rem;
    }` : ''
  }
`;