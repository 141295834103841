import React from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, image }) => {

  const { pathname } = useLocation();

  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          url
          image
        }
      }
    }
  `);

  const metadata = {
    ...site.siteMetadata,
    ...{
      title: title || site.siteMetadata.title,
      description: description || site.siteMetadata.description,
      image: image || site.siteMetadata.image
    }
  };

  return (
    <Helmet title={metadata.title} htmlAttributes={{ lang: metadata.lang || 'en' }}>
      <meta name="description" content={metadata.description} />
      <meta name="image" content={metadata.url + metadata.image} />

      <meta property="og:type" content="website" />
      {metadata.url && <meta property="og:url" content={metadata.url + pathname} />}
      {metadata.title && <meta property="og:title" content={metadata.title} />}
      {metadata.description && <meta property="og:description" content={metadata.description} />}
      {metadata.image && <meta property="og:image" content={metadata.url + metadata.image} />}

      <meta property="twitter:card" content="summary_large_image" />
      {metadata.url && <meta property="twitter:url" content={metadata.url + pathname} />}
      {metadata.title && <meta property="twitter:title" content={metadata.title} />}
      {metadata.description && <meta property="twitter:description" content={metadata.description} />}
      {metadata.image && <meta property="twitter:image" content={metadata.url + metadata.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
};

export default SEO;
