import { createGlobalStyle } from 'styled-components';

import '@fontsource/oswald/200.css';
import '@fontsource/oswald/300.css';
import '@fontsource/oswald/400.css';
import '@fontsource/oswald/500.css';
import '@fontsource/oswald/600.css';
import '@fontsource/oswald/700.css';
import '@fontsource/quattrocento/400.css';
import '@fontsource/quattrocento/700.css';

export const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    -webkit-print-color-adjust: exact;
    box-sizing: inherit;
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  body {
    color: ${({ theme }) => theme.colors.font};
    background-color: ${({ theme }) => theme.colors.background};
    font-size: 1.6rem;
    line-height: 2.3rem;
    font-weight: 400;
    margin: 0;
    font-family: 'Oswald', 'Tahoma', 'Arial', sans-serif;
  }

  strong,
  b {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 2rem;
    font-weight: 600;
  }

  h1 {
    font-size: 2.9rem;
    line-height: 3rem;
  }

  p,
  ul,
  ol,
  dl {
    margin: 0;
    margin-bottom: 3rem;
  }

  ul,
  ol,
  dl {
    padding-left: 1.5rem;
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    &[disabled] {
      pointer-events: none;
    }
  }

  p,
  input,
  textarea,
  select {
    font-family: 'Quattrocento', 'Georgia', 'Times New Roman', serif;
  }

  label,
  button {
    font-weight: 300;
    letter-spacing: .1rem;
    font-family: 'Oswald', 'Tahoma', 'Arial', sans-serif;
  }

  button,
  a {
    outline: none;
    line-height: inherit;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  address {
    font-style: normal;
  }

  hr {
    height: 0.1rem;
    display: block;
    border: none;
    border-top: 0.1rem solid #ccc;
    margin-bottom: 2rem;
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 1rem 0;
  }

  pre {
    margin: 0;
  }

  .gatsby-image-wrapper {
    [data-placeholder-image] {
      opacity: 0!important;
      transition: none!important;
    }
    img {
      opacity: 1!important;
      transition: none!important;
    }
  }
`;

