import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import themes from '~data/themes.yaml';

export const ThemesContext = React.createContext(
  themes.data
);

export const ThemeContextProvider = ({ children, name }) => {

  const themes = useContext(ThemesContext);
  const theme = themes[name] || themes.default;

  return (
    <ThemeProvider theme={{ ...themes.default, ...theme }}>
      {children}
    </ThemeProvider>
  )
};
